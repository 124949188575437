<template>
  <div class="fixed h-screen w-screen left-0 top-0 py-10 px-5 overflow-y-auto flex items-start justify-center bg-black bg-opacity-50" @click="close">
    <div :class="'bg-white rounded-lg shadow-lg ' + modalWidth + ' ' + modalHeight" @click.stop>
      <h3 class="mt-10 text-center text-5xl italic leading-6 font-medium text-gray-900 pb-10">
        <slot name="page-title"></slot>
      </h3>

      <hr class="pb-0.5" />

      <div class="mt-2 p-5 h-full">
        <slot name="static-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalWidth: {
      type: String,
      default: 'min-w-[700px]'
    },
    modalHeight: {
      type: String,
      default: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
