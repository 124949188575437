'use strict'

import {createRouter, createWebHistory} from 'vue-router'

import App from '@/App'
import staticRoutes from '@/pages/routes'
import NotFound from '@/pages/NotFound'
import RecentNewsLetter from '@/pages/RecentNewsLetter'
import PageProperties from '@/utilities/PageProperties'

const dynamicRoutes = [
  {
    path: '/:contentType/:slug',
    component: App,
    props(route) {
      return {pageProperties: PageProperties.fromRoute(route)}
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]
const routes = [
  {
    path: '/',
    component: RecentNewsLetter,
  },
  ...staticRoutes,
  ...dynamicRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
