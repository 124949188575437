<template>
  <embed-modal :url="embedUrl" page-title="Aanmelden voor de nieuwsbrief" modal-height="h-[320px]"></embed-modal>
</template>

<script>
import EmbedModal from '@/components/modals/EmbedModal'
import state from '@/utilities/state'

export default {
  components: {EmbedModal},
  computed: {
    customer () {
      return state.customer
    },
    embedUrl () {
      return 'https://embed.nexxtmove.nl/customers/'+this.customer.nexxtmove_id+'/subscribe'
    }
  },
}
</script>
