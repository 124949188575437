<template>
  <static-page-modal>
    <template v-slot:page-title>
      Contact informatie:
    </template>
    <template v-slot:static-content>
      <p>
        <span class="text-2xl">{{ customer.name }}</span>
      </p>
      <p>&nbsp;</p>
      <p class="pl-4">
        <font-awesome-icon icon="fa-solid fa-building"/>
        <span class="pl-3">{{ contact.address}} {{contact.city}}</span>
      </p>
      <p>&nbsp;</p>
      <p class="pl-4">
        <font-awesome-icon icon="fa-solid fa-phone" />
        <span class="pl-3">{{ contact.phone_number }}</span>
      </p>
      <p class="pl-4">
        <font-awesome-icon icon="fa-solid fa-envelope"/>
        <span class="pl-3">
          <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
        </span>
      </p>
    </template>
  </static-page-modal>
</template>

<script>
import StaticPageModal from '@/components/modals/StaticPageModal'
import state from '@/utilities/state'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  components: {FontAwesomeIcon, StaticPageModal},
  computed: {
    customer () {
      return state.customer
    },
    contact () {
      return state.customer.contact
    }
  }
}
</script>
