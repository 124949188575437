<template>
  <div class="pl-5">
    <p class="cursor-pointer" @click="$router.push({path: '/newsletter'})">
      <font-awesome-icon :icon="['fas', 'less-than']" /> Terug naar nieuwsbrief
    </p>
  </div>
</template>

<script>
export default {
  name: 'BackToRecentNewsletter',
}
</script>
