<template>
  <div ref="gallery" class="gallery pb-[87%] sm:pb-[80%]"></div>
</template>

<script>
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lightGallery from 'lightgallery'

export default {
  name: 'Gallery',
  props: {
    images: Array
  },
  mounted () {
    const container = this.$refs.gallery

    const options = {
      container,

      plugins: [lgThumbnail, lgZoom],

      closable: false,
      download: false,
      counter: false,

      allowMediaOverlap: true,
      // showMaximizeIcon: true,

      dynamic: true,
      dynamicEl: this.images
    }

    const gallery = lightGallery(container, options)

    gallery.openGallery()
  },
}
</script>

<style scoped>
/* Gallery */
@import "lightgallery/css/lightgallery.css";
@import "lightgallery/css/lg-thumbnail.css";
@import "lightgallery/css/lg-zoom.css";

.gallery {
  width: 100%;
  height: 0;
}

.gallery::v-deep .lg-outer .lg-object {
  vertical-align: top;
}
</style>
