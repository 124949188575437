<template>
  <embed-modal :url="embedUrl" pageTitle="Nieuwsbrief" modal-height="h-full"></embed-modal>
</template>

<script>
import EmbedModal from '@/components/modals/EmbedModal.vue'
import state from '@/utilities/state'
import PageProperties from '@/utilities/PageProperties'

export default {
  components: {EmbedModal},
  props: {
    pageProperties: PageProperties
  },
  computed: {
    customer () {
      return state.customer
    },
    embedUrl () {
      return 'https://embed.nexxtmove.nl/customers/' + this.customer.nexxtmove_id + '/newsletter/' + this.pageProperties.slug
    }
  }
}
</script>
