import App from '@/App'
import PageProperties from '@/utilities/PageProperties'
import pageDefinitions from '@/pages/definitions'

export default [
  {
    path: '/privacyverklaring',
    component: App,
    props () {
      return { pageProperties: PageProperties.fromDefinition(pageDefinitions.privacyStatement) }
    }
  },
  {
    path: '/disclaimer',
    component: App,
    props () {
      return { pageProperties: PageProperties.fromDefinition(pageDefinitions.disclaimer) }
    }
  },
  {
    path: '/newsletter',
    component: App,
    props () {
      return { pageProperties: PageProperties.fromDefinition(pageDefinitions.recentNewsletter) }
    }
  },
  {
    path: '/aanmelden',
    component: App,
    props () {
      return { pageProperties: PageProperties.fromDefinition(pageDefinitions.subscribe) }
    }
  },
  {
    path: '/contact',
    component: App,
    props () {
      return { pageProperties: PageProperties.fromDefinition(pageDefinitions.contact) }
    }
  }
]
