<template>
  <div class="fixed h-screen w-screen left-0 top-0 py-10 px-5 overflow-y-auto flex items-start justify-center bg-black bg-opacity-50" @click="close">
    <div class="bg-white rounded-lg shadow-lg max-w-[700px]" @click.stop>
      <img :src="content.main_image.url" :alt="content.main_image.name" class="w-full h-auto rounded-t-lg">

      <div class="p-5">
        <h1 class="text-3xl font-bold text-gray-900 pb-6">{{ content.title }}</h1>
        <hr class="pb-0.5" />
        <div v-html="content.content" class="article-content mt-2 "></div>
      </div>

      <back-to-recent-newsletter />
    </div>
  </div>
</template>

<script>
import BackToRecentNewsletter from '@/components/BackToRecentNewsletter'

export default {
  components: {BackToRecentNewsletter},
  props: {
    content: Object,
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.article-content {
  word-break: break-word;
}

.article-content p {
  @apply mb-5;
}

.article-content a {
  @apply text-slate-600 underline;
}

.article-content h1 {
  @apply text-3xl font-bold;
}

.article-content h2 {
  @apply text-xl mb-5 font-semibold;
}

.article-content h3 {
  @apply text-xl mb-1 font-semibold;
}

.article-content ul {
  @apply list-disc pl-5 mb-5;
}

.article-content ol {
  @apply list-decimal pl-5 mb-5
}
</style>
