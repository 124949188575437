<template>
  <static-page-modal modal-width="min-w-[70%]" :modal-height="modalHeight">
    <template v-slot:page-title>
      {{ pageTitle }}
    </template>

    <template v-slot:static-content>
      <iframe allowTransparency="true" :src="url"></iframe>
    </template>
  </static-page-modal>
</template>

<script>
import StaticPageModal from '@/components/modals/StaticPageModal'

export default {
  components: {StaticPageModal},
  props: {
    pageTitle: String,
    url: String,
    modalHeight: {
      type: String,
      default: ''
    },
    frameHeight: {
      type: String,
      default: '0'
    }
  },
  computed: {
    iFrameHeightClass () {
      return 'h-[' + this.frameHeight + ']'
    },
  }
}
</script>

<style scoped>
iframe {
  border: none;
  width: 100%;
  min-height: 80%;
}
</style>
