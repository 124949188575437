'use strict'

import 'tailwindcss/tailwind.css'
import { createApp } from 'vue'
import { RouterView } from 'vue-router'

/* Import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Import specific icons */
import { faPhone, faBuilding, faEnvelope, faLessThan } from '@fortawesome/free-solid-svg-icons'

import router from '@/router'
import state from '@/utilities/state'

const MAX_DOMAIN_PARTS_LENGTH = 3

const parts = window.location.host.split('.')

if(parts.length !== MAX_DOMAIN_PARTS_LENGTH || parts[0] === 'www') {
  state.setCustomerDomain(null)

  window.location.assign('https://nexxtmove.nl/')
}
else {
  state.setCustomerDomain(parts[0])
}

const app = createApp(RouterView)
  .use(router)

/* Add icons to the library */
library.add(faPhone, faBuilding, faEnvelope, faLessThan)

app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
