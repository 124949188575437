<template>
  <Loader v-if="loading" />

  <div v-else-if="!notFound">
    <DynamicBackground :src="customer.imageData"></DynamicBackground>
    <embed-modal :url="embedUrl" pageTitle="Laatste nieuwsbrief" modal-height="h-full"></embed-modal>
  </div>

  <NotFound v-else />
</template>

<script>
import EmbedModal from '@/components/modals/EmbedModal'
import state from '@/utilities/state'
import axios from 'axios'
import DynamicBackground from '@/components/DynamicBackground.vue'
import NotFound from '@/pages/NotFound.vue'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    Loader,
    NotFound,
    DynamicBackground,
    EmbedModal
  },
  computed: {
    state () {
      return state
    },
    customer () {
      return this.state.customer
    },
    embedUrl () {
      return 'https://embed.nexxtmove.nl/customers/' + this.customer.nexxtmove_id + '/newsletter'
    }
  },
  data () {
    return {
      loading: false,
      notFound: false
    }
  },
  created() {
    this.loading = true

    axios.get(this.embedUrl)
      .then(() => this.loading = false)
      .catch(() => window.location.assign(this.customer.website))
  },
  async beforeRouteEnter() {
    try {
      await state.load(state.getCustomerDomain(), true)
    } catch (e) {
      this.loading = false
      this.notFound = true
    }
  }
}
</script>
