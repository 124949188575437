import pageDefinitions, {PAGE_STATIC} from '@/pages/definitions'

class PageProperties {
  contentType
  slug

  #pageType
  #pageComponent

  static fromRoute(route) {
    const pageDefinition = PageProperties.getPageDefinitionBy(route.params.contentType)
    const properties = PageProperties.fromDefinition(pageDefinition)

    const pattern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/i
    let contentId = route.params.slug

    // A 'contentId' of 5 characters or fewer are most likely a regular numeric identifier,
    // so skip the BASE64 decoding step.
    if(contentId.length > 5 && pattern.test(contentId)) {
      contentId = atob(contentId)
    }

    if(contentId.includes('-')){
      contentId = contentId.split('-')[1]
    }

    properties.slug = contentId

    return properties
  }
  static fromDefinition(definition) {
    const properties = new this()

    if(definition === undefined) {
      return properties
    }

    properties.#pageType = definition.pageType ?? PAGE_STATIC
    properties.#pageComponent = definition.component
    properties.contentType = definition.contentType

    return properties
  }

  static getPageDefinitionBy(contentType) {
    return Object.entries(pageDefinitions).filter((d) => {
      const definition = d[1]
      const bool = definition.contentType === contentType

      if (!bool && definition.aliases.length > 0) {
        return definition.aliases.includes(contentType)
      }

      return bool
    })[0][1]
  }

  getComponent() {
    return this.#pageComponent
  }

  isStaticPage() {
    return (this.#pageType === PAGE_STATIC)
  }
}

export default PageProperties
