<template>
<static-page-modal>
  <template v-slot:page-title>Disclaimer {{customer.name}}</template>

  <template v-slot:static-content>
    <div class="pb-5">
      <p>Ondanks de constante zorg en aandacht die wordt besteed aan de samenstelling van deze nieuwsbrief, is het mogelijk dat de informatie die in deze nieuwsbrief wordt gepubliceerd onvolledig c.q. onjuist is. Fouten (in de gegevensverwerking) kunnen echter niet altijd voorkomen worden.</p>
    </div>
    <div class="pb-5">
      <p>Wij kunnen er niet voor instaan dat de informatie in deze nieuwsbrief geschikt is voor het doel waarvoor de informatie door u wordt geraadpleegd.</p>
    </div>
    <div class="pb-5">
      <p>
        Alle informatie, producten en diensten worden aangeboden in een staat waarin deze zich feitelijk bevinden en zonder enige (impliciete) garantie of waarborg ten aanzien van hun deugdelijkheid, geschiktheid voor een bepaald doel of anderszins. Alle aansprakelijkheid voor enigerlei directe of indirecte schade, van welke aard dan ook, die voortvloeit uit of in enig opzicht verband houdt met het gebruik van de informatie in deze nieuwsbrief of met de tijdelijke onmogelijkheid om de informatie in deze nieuwsbrief te kunnen raadplegen, wordt uitgesloten.
      </p>
    </div>
    <div class="pb-5">
      <p>De lezer wordt altijd aangeraden onafhankelijke inlichtingen in te winnen en/of onderzoek te verrichten voor gebruik van via deze nieuwsbrief verkregen informatie.</p>
    </div>
  </template>
</static-page-modal>
</template>

<script>
import StaticPageModal from '@/components/modals/StaticPageModal'
import state from '@/utilities/state'

export default {
  components: {StaticPageModal},
  computed: {
    customer () {
      return state.customer
    }
  },
}
</script>
