<template>
  <div class="flex items-center justify-center w-screen h-screen">
    <div class="px-4 lg:py-12">
      <div class="lg:gap-4 lg:flex">
        <div class="flex flex-col items-center justify-center md:py-24 lg:py-32">
          <h1 class="font-bold text-blue-600 text-9xl">404</h1>
          <p class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl" >
            <span class="text-red-500">Oops!</span> Pagina niet gevonden
          </p>
          <p>&nbsp;</p>
          <p class="mb-8 text-center text-gray-500 md:text-lg">
            Helaas bestaat de door jou opgevraagde pagina niet.
          </p>
        </div>
        <div class="mt-4">
          <!-- Some space for a nice image or something like that -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
