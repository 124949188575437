<template>
  <Loader v-if="loading" />

  <div v-else-if="!notFound">
    <DynamicBackground :src="state.customer.imageData"></DynamicBackground>
    <component :is="pageProperties.getComponent()" :content="state.content" :pageProperties="pageProperties" @close="closeModal"></component>
  </div>

  <NotFound v-else />
</template>

<script>
import DynamicBackground from '@/components/DynamicBackground'
import Loader from '@/components/Loader'
import state from '@/utilities/state'
import NotFound from '@/pages/NotFound'
import PageProperties from '@/utilities/PageProperties'

export default {
  name: 'App',
  computed: {
    state () {
      return state
    }
  },
  components: {
    NotFound,
    DynamicBackground,
    Loader,
  },
  props: {
    pageProperties: PageProperties
  },
  data () {
    return {
      loading: false,
      notFound: false
    }
  },
  created () {
    this.load()
  },
  methods: {
    load () {
      this.loading = true

      state.onMobile()

      state.load(state.getCustomerDomain()).catch((error) => {
        if (error.status === 404 || !error.status) {
          this.notFound = true
          this.loading = false
        }
      }).finally(() => {
        if (state.customer.website) {
          this.setCustomerStyle()

          if (this.pageProperties === undefined) {
            // window.location.assign(state.customer.website)
          }

          if (!this.pageProperties.isStaticPage()) {
            if (this.pageProperties.contentType === undefined && this.pageProperties.slug === undefined) {
              // window.location.assign(state.customer.website)
            }

            this.loadContent()
          }
          else {
            this.loading = false
          }
        }
      })
    },
    loadContent () {
      state.loadContent(this.pageProperties.contentType, this.pageProperties.slug).catch((error) => {
        if (error.status === 404 || !error.status) {
          this.notFound = true
        }
      }).finally(() => {
        this.loading = false
      })
    },
    closeModal () {
      window.location.assign(state.customer.website)
    },
    setCustomerStyle () {
      const customerTheme = state.customer.theme
      let styleElement = document.createElement('style')

      styleElement.type = 'text/css'

      for (const fontKey in customerTheme.font) {
        let linkElement = document.createElement('link')

        linkElement.rel = 'stylesheet'
        linkElement.href = 'https://fonts.googleapis.com/css2?family=' + customerTheme.font[fontKey] + ':ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'

        document.head.append(linkElement)

        /* eslint-disable default-case */
        switch (fontKey) {
        case 'heading':
          styleElement.innerHTML = styleElement.innerHTML
            + 'h1, h2, h3, h4, h5, h6 {font-family: ' + customerTheme.font.heading + '}'
            + 'header {font-family: ' + customerTheme.font.heading + '}'
          break
        case 'main':
          styleElement.innerHTML = styleElement.innerHTML
            + 'body {font-family: ' + customerTheme.font.main + '}'
          break
        }
      }

      document.head.append(styleElement)
    }
  }
}
</script>

<style>
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin-bottom: 1rem;
}
</style>
