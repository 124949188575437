'use strict'

import axios from 'axios'
import state from '@/utilities/state'

class Api {
  constructor() {
    this.exception = undefined
    this.requestConfig = {}
    this.requestHeaders = {
      'X-For-Subdomain': null
    }

    this._axios = axios.create({
      baseURL: process.env.VUE_APP_API_HOST
    })
  }

  get(uri, params = null) {
    return this._axios.get(uri, this.buildConfig(params))
  }

  buildConfig (params) {
    const config = {}

    config.headers = this.requestHeaders
    config.headers['X-For-Subdomain'] = state.getCustomerDomain()

    if (params !== null && params !== undefined) {
      config.params = params
    }

    this.requestConfig = config

    return this.requestConfig
  }
}

export default new Api()
